import React from 'react';
import Helmet from 'react-helmet';
import Kursevi from '../Kursevi';
import PropTypes from 'prop-types';

const KurseviPageTemplate = ({
  title,
  logo,
  meta_title,
  meta_description,
  kursevi,
}) => {
  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-one-fifth is-1'>
                <img alt='' src={logo} />
              </div>
              <div className='column is-10'>
                <div className='section'>
                  <h1 className='title'>{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='section'>
            <h2 className='title has-text-weight-semibold is-size-2'>
              {kursevi.heading}
            </h2>
            <div className='is-size-5 has-text-justified'>
              {kursevi.description}
            </div>
            {/* <h3
              className='title has-text-weight-semibold is-size-3'
              style={{ color: '#008080' }}
            >
              Popust <b>10%</b> za <a href='/contact'>upis</a> do 15. 10. 2021.
              godine.
            </h3> */}
          </div>
          <div className='content'>
            <Kursevi data={kursevi.plans} />
          </div>
        </div>
      </section>
    </div>
  );
};

KurseviPageTemplate.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  kursevi: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

export default KurseviPageTemplate;

import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F7DC5F',
    color: '#922411',
  },
}));

const Kursevi = ({ data }) => {
  const classes = useStyles();
  return (
    <div className='columns is-multiline is-mobile'>
      {data.map((price) => (
        <div
          key={price.plan}
          className='column is-mobile'
          style={{ border: '1px solid #922411 ' }}
        >
          <div className='section'>
            <h2 className='has-text-centered has-text-weight-bold'>
              {price.plan}
            </h2>
            <figure className='image is20x20'>
              <img alt='' src={price.itemLogo} />
            </figure>

            <h2 className='is-size-3 has-text-weight-semibold has-text-primary has-text-centered'>
              <div>{price.price} RSD</div>
              <div>4 termina</div>
            </h2>
            <Accordion className={classes.root}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>
                  <div className='is-size-4 has-text-primary has-background-primary-invert'>
                    {price.description}
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ul>
                    {price.items.map((item) => (
                      <li key={item} className='is-size-6'>
                        {item}
                      </li>
                    ))}
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      ))}
    </div>
  );
};

Kursevi.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
      itemLogo: PropTypes.string,
    })
  ),
};

export default Kursevi;

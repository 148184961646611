import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import KurseviPageTemplate from '../components/KurseviPageTemplate';
import Layout from '../components/Layout';

const KurseviPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <KurseviPageTemplate
        title={frontmatter.title}
        meta_title={frontmatter.meta_title}
        meta_description={frontmatter.meta_description}
        kursevi={frontmatter.kursevi}
        logo={frontmatter.logo}
        itemLogo={frontmatter.itemLogo}
      />
    </Layout>
  );
};

KurseviPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default KurseviPage;

export const kurseviPageQuery = graphql`
  query KurseviPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        logo
        meta_title
        meta_description
        kursevi {
          heading
          description
          plans {
            description
            items
            plan
            price
            itemLogo
          }
        }
      }
    }
  }
`;
